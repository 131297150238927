<template>
  <v-container>
    <h2>Adviser's clients</h2>
    <v-list>
      <v-list-item
        v-for="(user, index) in clients"
        :key="user.uid"
        :to="{name:'UserEdit', params: {uid:user.uid}}"
        link
      >
        <v-list-item-content>
          <v-list-item-title> {{ user.auth.displayName }}</v-list-item-title>
          <v-list-item-subtitle>
            #{{ index + 1 }} {{ user.auth.email }} {{ user.auth.phone }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-btn color="secondary" @click="$router.push({name:'UserAdd'})">ADD USER</v-btn>
  </v-container>
</template>

<script>
import { listAdviserUsers, getCurrentUser } from '@/services/firebase';

export default {
  name: 'UsersIndex',

  data() {
    return {
      clients: [],
    };
  },

  mounted() {
    listAdviserUsers(getCurrentUser().uid)
      .then((response) => {
        response.forEach((u) => {
          const record = {
            uid: u.id,
            ...u.data(),
          };
          this.clients.push(record);
        });
      });
  },
};
</script>
