var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('h2', [_vm._v("Adviser's clients")]), _c('v-list', _vm._l(_vm.clients, function (user, index) {
    return _c('v-list-item', {
      key: user.uid,
      attrs: {
        "to": {
          name: 'UserEdit',
          params: {
            uid: user.uid
          }
        },
        "link": ""
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(user.auth.displayName))]), _c('v-list-item-subtitle', [_vm._v(" #" + _vm._s(index + 1) + " " + _vm._s(user.auth.email) + " " + _vm._s(user.auth.phone) + " ")])], 1)], 1);
  }), 1), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'UserAdd'
        });
      }
    }
  }, [_vm._v("ADD USER")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }